<template>
    <!-- Global notification live region, render this permanently at the end of the document -->
    <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6">
        <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
            <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
            <transition enter-active-class="transform ease-out duration-300 transition" enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                <div v-if="state.show" class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white dark:bg-gray-800 shadow-lg ring-1 ring-black ring-opacity-5">
                    <div class="p-4">
                        <div class="flex flex-row items-center">
                            <div v-if="flash.status === 'Completed'" class="flex-shrink-0">
                                <font-awesome-icon :icon="['far', 'check-circle']" class="h-5 w-5 text-green-400" aria-hidden="true" />
                            </div>
                            <div v-else-if="flash.status === 'Error'" class="flex-shrink-0">
                                <font-awesome-icon :icon="['far', 'exclamation-circle']" class="h-5 w-5 text-red-400" aria-hidden="true" />
                            </div>
                            <div v-else-if="flash.status === 'Information'" class="flex-shrink-0">
                                <font-awesome-icon :icon="['far', 'info-circle']" class="h-5 w-5 text-blue-400" aria-hidden="true" />
                            </div>
                            <div class="ml-3 w-0 flex-1 pb-0.5">
                                <p class="text-sm font-medium text-gray-900 dark:text-gray-300">{{ flash.message }}</p>
                            </div>
                            <div class="ml-4 flex flex-shrink-0">
                                <button type="button" @click="state.show = false" class="inline-flex rounded-md bg-white dark:bg-gray-900 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                    <span class="sr-only">Close</span>
                                    <font-awesome-icon :icon="['fas', 'xmark']" class="h-4 w-4" aria-hidden="true" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script setup>
import {onMounted, reactive} from 'vue'
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

defineProps(['flash'])

const state = reactive({
    show: true
})

onMounted(() => {
    setTimeout(() => {
        state.show = false
    }, 5000)
})
</script>

<style scoped>

</style>
